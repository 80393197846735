import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Playground, Props } from 'docz';
import { Intent } from '@class101/ui';
import { StyledInlineError as InlineError } from './InlineError';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "inlineerror"
    }}>{`InlineError`}</h1>
    <p>{`This is InlineError component.`}</p>
    <h2 {...{
      "id": "props"
    }}>{`Props`}</h2>
    <Props of={InlineError} mdxType="Props" />
    <h2 {...{
      "id": "basic-usage"
    }}>{`Basic usage`}</h2>
    <Playground __position={1} __code={'<InlineError intent={Intent.DANGER}>\n  보안에 매우 취약한 값입니다. 사용할 수 없습니다.\n</InlineError>\n<InlineError intent={Intent.WARNING}>\n  보안에 취약한 값입니다. 특수문자와 숫자를 섞어주세요\n</InlineError>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Intent,
      InlineError,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <InlineError intent={Intent.DANGER} mdxType="InlineError">보안에 매우 취약한 값입니다. 사용할 수 없습니다.</InlineError>
  <InlineError intent={Intent.WARNING} mdxType="InlineError">보안에 취약한 값입니다. 특수문자와 숫자를 섞어주세요</InlineError>
    </Playground>
    <h2 {...{
      "id": "non-icon-usage"
    }}>{`Non icon usage`}</h2>
    <Playground __position={2} __code={'<InlineError icon={null}>80자 이내로 작성해주세요</InlineError>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Intent,
      InlineError,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <InlineError icon={null} mdxType="InlineError">80자 이내로 작성해주세요</InlineError>
    </Playground>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      